import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  KeyValueStringArray: Array<{key: string; value: string}>;
};

export type Admin = {
  __typename?: 'Admin';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  group: GroupDto;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  permissions: Array<PermissionDto>;
  updatedAt: Scalars['DateTime'];
};

export type AdminFilterDto = {
  emailPart?: InputMaybe<Scalars['String']>;
};

export type AppBasicContent = {
  __typename?: 'AppBasicContent';
  content: AppBasicContentHtmlDto;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: AppContentType;
};

export type AppBasicContentHtmlDto = {
  __typename?: 'AppBasicContentHtmlDto';
  html: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type AppBasicContentHtmlInput = {
  html: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export enum AppBasicContentType {
  CguCodePromo = 'CGU_CODE_PROMO',
  ConfidentialityPolicy = 'CONFIDENTIALITY_POLICY',
  CookiePolicy = 'COOKIE_POLICY',
  CustomerServiceInfo = 'CUSTOMER_SERVICE_INFO',
  LegalNotice = 'LEGAL_NOTICE',
  PricingDocumentation = 'PRICING_DOCUMENTATION',
  TermsOfUse = 'TERMS_OF_USE',
  XpoAccountPolicy = 'XPO_ACCOUNT_POLICY',
  XpoCardPolicy = 'XPO_CARD_POLICY'
}

export enum AppContentType {
  CguCodePromo = 'CGU_CODE_PROMO',
  ConfidentialityPolicy = 'CONFIDENTIALITY_POLICY',
  CookiePolicy = 'COOKIE_POLICY',
  CustomerServiceInfo = 'CUSTOMER_SERVICE_INFO',
  Faq = 'FAQ',
  LegalNotice = 'LEGAL_NOTICE',
  PricingDocumentation = 'PRICING_DOCUMENTATION',
  TermsOfUse = 'TERMS_OF_USE',
  XpoAccountPolicy = 'XPO_ACCOUNT_POLICY',
  XpoCardPolicy = 'XPO_CARD_POLICY'
}

export type AppFaqContent = {
  __typename?: 'AppFaqContent';
  content: AppFaqContentContentDto;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type AppFaqContentCategoryDto = {
  __typename?: 'AppFaqContentCategoryDto';
  description?: Maybe<Scalars['String']>;
  optionalIdentifier?: Maybe<Scalars['String']>;
  posts: Array<AppFaqContentPostDto>;
  subcategories?: Maybe<Array<AppFaqContentCategoryDto>>;
  title: Scalars['String'];
};

export type AppFaqContentCategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  optionalIdentifier?: InputMaybe<Scalars['String']>;
  posts: Array<AppFaqContentPostInput>;
  subcategories?: InputMaybe<Array<AppFaqContentCategoryInput>>;
  title: Scalars['String'];
};

export type AppFaqContentContentDto = {
  __typename?: 'AppFaqContentContentDto';
  categories: Array<AppFaqContentCategoryDto>;
  title?: Maybe<Scalars['String']>;
};

export type AppFaqContentContentInput = {
  categories: Array<AppFaqContentCategoryInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type AppFaqContentPostDto = {
  __typename?: 'AppFaqContentPostDto';
  content: Scalars['String'];
  optionalIdentifier?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AppFaqContentPostInput = {
  content: Scalars['String'];
  optionalIdentifier?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum AuthenticationErrors {
  BadCredentials = 'BAD_CREDENTIALS',
  TooManyAttempts = 'TOO_MANY_ATTEMPTS'
}

export type CarrouselImage = {
  __typename?: 'CarrouselImage';
  appearanceOrder: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageBytes: Scalars['String'];
  internalUrl?: Maybe<InternalUrl>;
  name: Scalars['String'];
  offerType: OfferTypeEnum;
};

export type CarrouselImageFilterDto = {
  offerType?: InputMaybe<OfferTypeEnum>;
};

export type Cart = {
  __typename?: 'Cart';
  client: Client;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Array<CartItem>;
  paidAt?: Maybe<Scalars['DateTime']>;
  payerId: Scalars['String'];
  paymentWorkflows?: Maybe<Array<PaymentWorkflow>>;
  updatedAt: Scalars['DateTime'];
};

export type CartFilterDto = {
  createdAtEndDate?: InputMaybe<Scalars['DateTime']>;
  createdAtStartDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  payerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CartStatus>;
};

export type CartItem = {
  __typename?: 'CartItem';
  amountInCents: Scalars['Int'];
  cart: Cart;
  cartId: Scalars['String'];
  cbsTransactionId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  payable: Payable;
  payableId: Scalars['String'];
  serviceFeeInCents: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum CartStatus {
  Paid = 'PAID',
  ToPay = 'TO_PAY'
}

export type CessationRaFilterDto = {
  nirioUserId: Scalars['String'];
};

export enum CessationRaInitiative {
  Bank = 'BANK',
  Client = 'CLIENT'
}

export enum CessationRaReason {
  AnotherBank = 'ANOTHER_BANK',
  BadClientSupport = 'BAD_CLIENT_SUPPORT',
  IssueNotResolved = 'ISSUE_NOT_RESOLVED',
  MissingServices = 'MISSING_SERVICES',
  NoUse = 'NO_USE',
  Other = 'OTHER',
  TooExpensive = 'TOO_EXPENSIVE'
}

export enum CessationRaSource {
  App = 'APP',
  Backoffice = 'BACKOFFICE',
  Debt = 'DEBT',
  Xpo = 'XPO'
}

export type CessationRaWorkflow = {
  __typename?: 'CessationRaWorkflow';
  createdAt: Scalars['DateTime'];
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiative: CessationRaInitiative;
  isDowngrade: Scalars['Boolean'];
  nirioUserId: Scalars['String'];
  reason?: Maybe<CessationRaReason>;
  reasonComment?: Maybe<Scalars['String']>;
  source: CessationRaSource;
  xpoReason: CessationRaXpoReason;
};

export enum CessationRaXpoReason {
  BankingMobility = 'BANKING_MOBILITY',
  CommercialRelationshipEnd = 'COMMERCIAL_RELATIONSHIP_END',
  Deceased = 'DECEASED',
  FccFicp = 'FCC_FICP',
  Fraud = 'FRAUD',
  Inactive = 'INACTIVE',
  NotCompliantTou = 'NOT_COMPLIANT_TOU',
  Retractation = 'RETRACTATION'
}

export type Client = {
  __typename?: 'Client';
  carts: Array<Cart>;
  cbsClientId?: Maybe<Scalars['String']>;
  cbsProfileUrl?: Maybe<Scalars['String']>;
  cessationRaWorkflow?: Maybe<CessationRaWorkflow>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nirioUserId?: Maybe<Scalars['String']>;
  payables: Array<Payable>;
  profile: Scalars['String'];
  status: PayerStatus;
  updatedAt: Scalars['DateTime'];
  xpoAppUserId?: Maybe<Scalars['String']>;
  xpoProfileUrl?: Maybe<Scalars['String']>;
};

export type ClientFilterDto = {
  createdAtGte?: InputMaybe<Scalars['DateTime']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  nirioBankDebtCreatedAtGte?: InputMaybe<Scalars['DateTime']>;
  nirioBankDebtCreatedAtLte?: InputMaybe<Scalars['DateTime']>;
  partialCbsClientId?: InputMaybe<Scalars['String']>;
  partialEmail?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Array<ClientProfile>>;
  status?: InputMaybe<Array<PayerStatus>>;
};

export enum ClientProfile {
  CdrCorporation = 'CDR_CORPORATION',
  CdrNatural = 'CDR_NATURAL',
  Coap = 'COAP',
  Cosp = 'COSP',
  CracAvecCb = 'CRAC_AVEC_CB',
  CracSansCb = 'CRAC_SANS_CB',
  PeageAnonyme = 'PEAGE_ANONYME',
  Unknown = 'UNKNOWN'
}

export enum CollectionType {
  Avp = 'AVP',
  Depot = 'DEPOT',
  Facture = 'FACTURE',
  Peage = 'PEAGE',
  Retrait = 'RETRAIT'
}

export type CountDto = {
  __typename?: 'CountDto';
  count: Scalars['Int'];
};

export type CraClosingReasonDto = {
  __typename?: 'CraClosingReasonDto';
  code: CessationRaReason;
  hasComment: Scalars['Boolean'];
  label: Scalars['String'];
};

export type CreateNirioBankWelcomeCodeError = {
  __typename?: 'CreateNirioBankWelcomeCodeError';
  codeErr: CreateNirioBankWelcomeCodeErrorCode;
};

export enum CreateNirioBankWelcomeCodeErrorCode {
  ReferrerCodeFound = 'REFERRER_CODE_FOUND',
  RetailerFormatCode = 'RETAILER_FORMAT_CODE'
}

export type CreateNirioBankWelcomeCodeMultipleUsageResult = CreateNirioBankWelcomeCodeError | NirioBankWelcomeCode;

export type CreatePartnerAuthDto = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export enum Currency {
  Eur = 'EUR',
  Xpf = 'XPF'
}

export type CustomerServiceInfo = {
  __typename?: 'CustomerServiceInfo';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  openingHours: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  neptuneSimulator: OnOff;
  operatorAuthorizationCheck: OnOff;
};

export type GetNirioUserKycWorkflowsWithCountDto = {
  __typename?: 'GetNirioUserKycWorkflowsWithCountDto';
  items: Array<KycWorkflow>;
  total: Scalars['Int'];
};

export enum GroupDto {
  FdjAdmin = 'FDJ_ADMIN',
  FdjCompliance = 'FDJ_COMPLIANCE',
  FdjCustomerService = 'FDJ_CUSTOMER_SERVICE',
  FdjMiddleOffice = 'FDJ_MIDDLE_OFFICE',
  PolyAdmin = 'POLY_ADMIN',
  PolyUser = 'POLY_USER',
  RetCcrv = 'RET_CCRV',
  RetCcrvManager = 'RET_CCRV_MANAGER'
}

export enum InternalUrl {
  Appbasiccontent = 'APPBASICCONTENT',
  Otherurl = 'OTHERURL'
}

export type KycWorkflow = {
  __typename?: 'KycWorkflow';
  abortedReason?: Maybe<KycWorkflowAbortedReason>;
  cisDocumentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  externalUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isManual: Scalars['Boolean'];
  stage: KycWorkflowStage;
};

export enum KycWorkflowAbortedReason {
  AnDocumentMalformed = 'AN_DOCUMENT_MALFORMED',
  AnDocumentMissing = 'AN_DOCUMENT_MISSING',
  DocumentExpired = 'DOCUMENT_EXPIRED',
  ExpiredWorkflow = 'EXPIRED_WORKFLOW',
  FraudulentDocumentDetected = 'FRAUDULENT_DOCUMENT_DETECTED',
  InternalError = 'INTERNAL_ERROR',
  MalformedReport = 'MALFORMED_REPORT',
  ManyDocumentsPerWorkflow = 'MANY_DOCUMENTS_PER_WORKFLOW',
  MissingNirioUser = 'MISSING_NIRIO_USER',
  MissingNirioUserEmail = 'MISSING_NIRIO_USER_EMAIL',
  PayerIsUnderage = 'PAYER_IS_UNDERAGE',
  UnknownDocumentError = 'UNKNOWN_DOCUMENT_ERROR',
  UnrecognizedDocument = 'UNRECOGNIZED_DOCUMENT',
  UnsupportedDocument = 'UNSUPPORTED_DOCUMENT',
  WrongPayerTypeOrStatus = 'WRONG_PAYER_TYPE_OR_STATUS'
}

export enum KycWorkflowStage {
  CompletedKo = 'COMPLETED_KO',
  CompletedOk = 'COMPLETED_OK',
  Init = 'INIT',
  Pending = 'PENDING'
}

export type MaintenanceSettingsDto = {
  __typename?: 'MaintenanceSettingsDto';
  go: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  premio: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAdmin: Admin;
  createCarrouselImage: CarrouselImage;
  createCessationRaWorkflow: CessationRaWorkflow;
  createNirioBankWelcomeCodeMultipleUsage: CreateNirioBankWelcomeCodeMultipleUsageResult;
  createNirioBankWelcomePromotion: NirioBankWelcomePromotion;
  createPartner: Partner;
  createProduct: Product;
  deleteAdmin: Admin;
  deleteCarrouselImage: CarrouselImage;
  logout: Scalars['String'];
  refreshAccessToken: RefreshAccessTokenResult;
  sendMagicLink: SendMagicLinkResult;
  signinAdminWithMagicLinkToken: SigninAdminWithMagicLinkTokenResult;
  updateAdmin: Admin;
  updateAppBasicContent: AppBasicContent;
  updateAppFaqContent: AppFaqContent;
  updateCustomerServiceInfo: CustomerServiceInfo;
  updateMaintenanceSettings: MaintenanceSettingsDto;
  updateNirioBankWelcomePromotion: NirioBankWelcomePromotion;
  updatePartner: Partner;
  updateProduct: Product;
  updatePromotionSettingsReferrer: PromotionSettings;
  updatePromotionSettingsRetailer: PromotionSettings;
};


export type MutationCreateAdminArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  group: GroupDto;
  lastName: Scalars['String'];
};


export type MutationCreateCarrouselImageArgs = {
  appearanceOrder: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  imageBytes: Scalars['String'];
  internalUrl?: InputMaybe<InternalUrl>;
  name: Scalars['String'];
  offerType: OfferTypeEnum;
};


export type MutationCreateCessationRaWorkflowArgs = {
  iban: Scalars['String'];
  initiative: CessationRaInitiative;
  isDowngrade: Scalars['Boolean'];
  nirioUserId: Scalars['String'];
  reason?: InputMaybe<CessationRaReason>;
  reasonComment?: InputMaybe<Scalars['String']>;
};


export type MutationCreateNirioBankWelcomeCodeMultipleUsageArgs = {
  code: Scalars['String'];
  maxClaimed: Scalars['Int'];
  promotionId: Scalars['String'];
};


export type MutationCreateNirioBankWelcomePromotionArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  freeMonthsCount?: InputMaybe<Scalars['Int']>;
  gainAmountInCents?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  offers?: Array<Scalars['String']>;
  requiredPaymentsCount?: InputMaybe<Scalars['Int']>;
  requiredPaymentsMinimumAmountInCents?: InputMaybe<Scalars['Int']>;
  requiredPaymentsSlidingDays?: InputMaybe<Scalars['Int']>;
  type: NirioBankWelcomePromotionType;
};


export type MutationCreatePartnerArgs = {
  datamatrixPartnerId: Scalars['String'];
  datamatrixSignatureConfig?: InputMaybe<PartnerDatamatrixSignatureConfig>;
  displayOrderInApp: Scalars['Float'];
  isDisplayed: Scalars['Boolean'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  partnerAuth?: InputMaybe<CreatePartnerAuthDto>;
};


export type MutationCreateProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowedPaymentMethods?: InputMaybe<Array<PaymentMethod>>;
  apiUrl?: InputMaybe<Scalars['String']>;
  authenticationMode?: InputMaybe<ProductAuthenticationMode>;
  authenticationUrl?: InputMaybe<Scalars['String']>;
  canBePaidByThirdParty?: InputMaybe<Scalars['Boolean']>;
  canBePartiallyPaid?: InputMaybe<Scalars['Boolean']>;
  canExpire?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['String']>;
  cbsMerchantId?: InputMaybe<Scalars['String']>;
  credentialsPassword?: InputMaybe<Scalars['String']>;
  credentialsUsername?: InputMaybe<Scalars['String']>;
  datamatrixProductId: Scalars['String'];
  maximumPaymentInCents: Scalars['Int'];
  minimumPaymentInCents: Scalars['Int'];
  name: Scalars['String'];
  neptuneReceiptLogo?: InputMaybe<Scalars['String']>;
  oauthBody?: InputMaybe<Scalars['KeyValueStringArray']>;
  oauthBodyEncoding?: InputMaybe<OauthBodyEncoding>;
  partnerId?: InputMaybe<Scalars['String']>;
  serviceFeeDescription?: InputMaybe<Scalars['String']>;
  transactionNoticeUrl?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteAdminArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCarrouselImageArgs = {
  id: Scalars['ID'];
};


export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationSendMagicLinkArgs = {
  email: Scalars['String'];
};


export type MutationSigninAdminWithMagicLinkTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateAdminArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<GroupDto>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAppBasicContentArgs = {
  content: AppBasicContentHtmlInput;
  type: AppBasicContentType;
};


export type MutationUpdateAppFaqContentArgs = {
  content: AppFaqContentContentInput;
};


export type MutationUpdateCustomerServiceInfoArgs = {
  id: Scalars['ID'];
  openingHours: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationUpdateMaintenanceSettingsArgs = {
  go: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  premio: Scalars['Boolean'];
};


export type MutationUpdateNirioBankWelcomePromotionArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  gainAmountInCents?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  offers?: Array<Scalars['String']>;
};


export type MutationUpdatePartnerArgs = {
  datamatrixPartnerId?: InputMaybe<Scalars['String']>;
  datamatrixSignatureConfig?: InputMaybe<PartnerDatamatrixSignatureConfig>;
  displayOrderInApp?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDisplayed?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerAuth?: InputMaybe<CreatePartnerAuthDto>;
  products?: InputMaybe<Array<ProductInputDto>>;
};


export type MutationUpdateProductArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowedPaymentMethods?: InputMaybe<Array<PaymentMethod>>;
  apiUrl?: InputMaybe<Scalars['String']>;
  authenticationMode?: InputMaybe<ProductAuthenticationMode>;
  authenticationUrl?: InputMaybe<Scalars['String']>;
  canBePaidByThirdParty?: InputMaybe<Scalars['Boolean']>;
  canBePartiallyPaid?: InputMaybe<Scalars['Boolean']>;
  canExpire?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['String']>;
  cbsMerchantId?: InputMaybe<Scalars['String']>;
  credentialsPassword?: InputMaybe<Scalars['String']>;
  credentialsUsername?: InputMaybe<Scalars['String']>;
  datamatrixProductId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  maximumPaymentInCents?: InputMaybe<Scalars['Int']>;
  minimumPaymentInCents?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  neptuneReceiptLogo?: InputMaybe<Scalars['String']>;
  oauthBody?: InputMaybe<Scalars['KeyValueStringArray']>;
  oauthBodyEncoding?: InputMaybe<OauthBodyEncoding>;
  partnerId?: InputMaybe<Scalars['String']>;
  serviceFeeDescription?: InputMaybe<Scalars['String']>;
  transactionNoticeUrl?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePromotionSettingsReferrerArgs = {
  gainAmountInCents: Scalars['Float'];
  id: PromotionSettingsType;
  isActive?: Scalars['Boolean'];
  referrerGainAmountInCents?: InputMaybe<Scalars['Float']>;
  requiredPaymentsCount: Scalars['Float'];
  requiredPaymentsMinimumAmountInCents: Scalars['Float'];
  requiredPaymentsSlidingDays: Scalars['Float'];
  slidingMonthLimit: Scalars['Float'];
  userLimit: Scalars['Float'];
};


export type MutationUpdatePromotionSettingsRetailerArgs = {
  gainAmountInCents: Scalars['Float'];
  id: PromotionSettingsType;
  isActive?: Scalars['Boolean'];
  requiredPaymentsCount: Scalars['Float'];
  requiredPaymentsMinimumAmountInCents: Scalars['Float'];
  requiredPaymentsSlidingDays: Scalars['Float'];
  retailerGainAmountInCents?: InputMaybe<Scalars['Float']>;
  slidingMonthLimit: Scalars['Float'];
  userLimit: Scalars['Float'];
};

export type NirioBankBeneficiaryDto = {
  __typename?: 'NirioBankBeneficiaryDto';
  beneficiaryId?: Maybe<Scalars['String']>;
  bic: Scalars['String'];
  displayName: Scalars['String'];
  iban: Scalars['String'];
};

export type NirioBankDebt = {
  __typename?: 'NirioBankDebt';
  amountInCents: Scalars['Int'];
  cbsId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  nirioUserId: Scalars['ID'];
  operationType?: Maybe<NirioBankOperationType>;
  origin: NirioBankDebtOrigin;
  paidAt?: Maybe<Scalars['DateTime']>;
  remainingAmountInCents: Scalars['Int'];
};

export type NirioBankDebtFilterDto = {
  nirioUserId?: InputMaybe<Scalars['String']>;
  origins?: InputMaybe<Array<NirioBankDebtOrigin>>;
};

export enum NirioBankDebtOrigin {
  CardOrderFee = 'CARD_ORDER_FEE',
  CardPayment = 'CARD_PAYMENT',
  DirectDebitFee = 'DIRECT_DEBIT_FEE',
  OperationFee = 'OPERATION_FEE',
  PointOfSaleWithdrawal = 'POINT_OF_SALE_WITHDRAWAL',
  SubscriptionFee = 'SUBSCRIPTION_FEE'
}

export enum NirioBankOperationType {
  CardCreationFee = 'CARD_CREATION_FEE',
  CardCredit = 'CARD_CREDIT',
  CashDeposit = 'CASH_DEPOSIT',
  CashWithdrawal = 'CASH_WITHDRAWAL',
  CommercialGesture = 'COMMERCIAL_GESTURE',
  DirectDebitFee = 'DIRECT_DEBIT_FEE',
  ExceptionalAct = 'EXCEPTIONAL_ACT',
  InternalTransferIssued = 'INTERNAL_TRANSFER_ISSUED',
  InternalTransferReceived = 'INTERNAL_TRANSFER_RECEIVED',
  NirioFee = 'NIRIO_FEE',
  OutgoingDebit = 'OUTGOING_DEBIT',
  OutgoingDebitRefund = 'OUTGOING_DEBIT_REFUND',
  PaymentByCard = 'PAYMENT_BY_CARD',
  PaymentByCardRefund = 'PAYMENT_BY_CARD_REFUND',
  ReferralPromotionBenefit = 'REFERRAL_PROMOTION_BENEFIT',
  RetailerPromotionBenefit = 'RETAILER_PROMOTION_BENEFIT',
  TopUpCard = 'TOP_UP_CARD',
  TransferIssued = 'TRANSFER_ISSUED',
  TransferIssuedRefund = 'TRANSFER_ISSUED_REFUND',
  TransferReceived = 'TRANSFER_RECEIVED',
  TransferReceivedRefund = 'TRANSFER_RECEIVED_REFUND',
  WelcomePromotionBenefit = 'WELCOME_PROMOTION_BENEFIT',
  WithdrawalAtm = 'WITHDRAWAL_ATM'
}

export type NirioBankPromotionOffer = {
  __typename?: 'NirioBankPromotionOffer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NirioBankWelcomeCode = {
  __typename?: 'NirioBankWelcomeCode';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maxClaimed: Scalars['Int'];
  promotionId: Scalars['String'];
};

export type NirioBankWelcomeCodeFilterDto = {
  promotionId: Scalars['String'];
};

export type NirioBankWelcomePromotion = {
  __typename?: 'NirioBankWelcomePromotion';
  createdAt: Scalars['DateTime'];
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  freeMonthsCount?: Maybe<Scalars['Int']>;
  gainAmountInCents?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  offers: Array<Scalars['String']>;
  requiredPaymentsCount?: Maybe<Scalars['Int']>;
  requiredPaymentsMinimumAmountInCents?: Maybe<Scalars['Int']>;
  requiredPaymentsSlidingDays?: Maybe<Scalars['Int']>;
  type: NirioBankWelcomePromotionType;
  updatedAt: Scalars['DateTime'];
};

export type NirioBankWelcomePromotionFilterDto = {
  nameFilter?: InputMaybe<Scalars['String']>;
};

export enum NirioBankWelcomePromotionType {
  FreeMonths = 'FREE_MONTHS',
  Monetary = 'MONETARY'
}

export enum OauthBodyEncoding {
  Json = 'JSON',
  UrlForm = 'URL_FORM'
}

export enum OfferTypeEnum {
  Go = 'GO',
  Premio = 'PREMIO'
}

export enum OnOff {
  Off = 'OFF',
  On = 'ON'
}

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type Partner = {
  __typename?: 'Partner';
  createdAt: Scalars['DateTime'];
  datamatrixPartnerId: Scalars['String'];
  datamatrixSignatureConfig: PartnerDatamatrixSignatureConfig;
  displayOrderInApp: Scalars['Float'];
  id: Scalars['ID'];
  isDisplayed: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  partnerAuth?: Maybe<PartnerAuth>;
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
};

export type PartnerAuth = {
  __typename?: 'PartnerAuth';
  username: Scalars['String'];
};

export enum PartnerDatamatrixSignatureConfig {
  AllowBoth = 'ALLOW_BOTH',
  OnlyAllowSigned = 'ONLY_ALLOW_SIGNED',
  OnlyAllowUnsigned = 'ONLY_ALLOW_UNSIGNED'
}

export type PartnerFiltersDto = {
  createdAtGte?: InputMaybe<Scalars['DateTime']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']>;
  idPart?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
};

export type PartnerProductCategoryDto = {
  code: Scalars['String'];
  label: Scalars['String'];
};

export type Payable = {
  __typename?: 'Payable';
  client?: Maybe<Client>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  datamatrixContent?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  freeField1?: Maybe<Scalars['String']>;
  freeField2?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialAmountInCents: Scalars['Int'];
  items?: Maybe<Array<CartItem>>;
  partnerCustomerId?: Maybe<Scalars['String']>;
  partnerPayableId: Scalars['String'];
  payerId: Scalars['String'];
  product?: Maybe<Product>;
  productId: Scalars['String'];
  remainingAmountInCents: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PayableFilterDto = {
  category?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<Array<Scalars['String']>>;
  partnerCustomerId?: InputMaybe<Scalars['String']>;
  partnerPayableId?: InputMaybe<Scalars['String']>;
  payerId?: InputMaybe<Scalars['String']>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export enum PayerStatus {
  Blocked = 'BLOCKED',
  Closed = 'CLOSED',
  Error = 'ERROR',
  Open = 'OPEN',
  Pending = 'PENDING',
  ToFix = 'TO_FIX'
}

export enum PaymentMethod {
  BankCard = 'BANK_CARD',
  Cash = 'CASH'
}

export enum PaymentStatus {
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  ToPay = 'TO_PAY'
}

export type PaymentWorkflow = {
  __typename?: 'PaymentWorkflow';
  amountInCents: Scalars['Int'];
  cart: Cart;
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentMethod?: Maybe<PaymentMethod>;
  retailer: Retailer;
  status: PaymentWorkflowStatus;
  updatedAt: Scalars['DateTime'];
};

export type PaymentWorkflowCartFilterDto = {
  id?: InputMaybe<Scalars['String']>;
  paidAtEnd?: InputMaybe<Scalars['DateTime']>;
  paidAtStart?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentWorkflowFilterDto = {
  cart?: InputMaybe<PaymentWorkflowCartFilterDto>;
  cbsTransactionId?: InputMaybe<Scalars['String']>;
  createdAtEnd?: InputMaybe<Scalars['DateTime']>;
  createdAtStart?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  payableId?: InputMaybe<Scalars['ID']>;
  retailer?: InputMaybe<PaymentWorkflowRetailerFilterDto>;
  status?: InputMaybe<Array<PaymentWorkflowStatus>>;
};

export type PaymentWorkflowRetailerFilterDto = {
  departmentCodes?: InputMaybe<Array<Scalars['String']>>;
  profiles?: InputMaybe<Array<ClientProfile>>;
  rdiRoleCode?: InputMaybe<Scalars['String']>;
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
};

export enum PaymentWorkflowStatus {
  Blocked = 'BLOCKED',
  Closed = 'CLOSED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING'
}

export enum PermissionDto {
  AdminDelete = 'AdminDelete',
  AdminRead = 'AdminRead',
  AdminWrite = 'AdminWrite',
  AppContentRead = 'AppContentRead',
  AppContentWrite = 'AppContentWrite',
  CarrouselImageRead = 'CarrouselImageRead',
  CarrouselImageWrite = 'CarrouselImageWrite',
  CartRead = 'CartRead',
  CraRead = 'CraRead',
  CraWrite = 'CraWrite',
  CustomerServiceRead = 'CustomerServiceRead',
  CustomerServiceWrite = 'CustomerServiceWrite',
  MaintenanceRead = 'MaintenanceRead',
  MaintenanceWrite = 'MaintenanceWrite',
  MerchantRead = 'MerchantRead',
  NeptuneRead = 'NeptuneRead',
  NeptuneWrite = 'NeptuneWrite',
  PartnerRead = 'PartnerRead',
  PartnerWrite = 'PartnerWrite',
  PayableRead = 'PayableRead',
  PayerRead = 'PayerRead',
  PaymentWorkflowRead = 'PaymentWorkflowRead',
  ProductRead = 'ProductRead',
  ProductWrite = 'ProductWrite',
  PromotionRead = 'PromotionRead',
  PromotionWrite = 'PromotionWrite',
  RetailerRead = 'RetailerRead'
}

export type Product = {
  __typename?: 'Product';
  active: Scalars['Boolean'];
  allowedPaymentMethods?: Maybe<Array<PaymentMethod>>;
  apiUrl?: Maybe<Scalars['String']>;
  authenticationMode?: Maybe<ProductAuthenticationMode>;
  authenticationUrl?: Maybe<Scalars['String']>;
  canBePaidByThirdParty: Scalars['Boolean'];
  canBePartiallyPaid: Scalars['Boolean'];
  canExpire: Scalars['Boolean'];
  category: ProductCategory;
  categoryId: Scalars['String'];
  cbsMerchantId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  credentialsUsername?: Maybe<Scalars['String']>;
  datamatrixProductId: Scalars['String'];
  externalUrl: Scalars['String'];
  hasEncryptedCredentials: Scalars['Boolean'];
  id: Scalars['ID'];
  maximumPaymentInCents?: Maybe<Scalars['Int']>;
  minimumPaymentInCents: Scalars['Int'];
  name: Scalars['String'];
  neptuneReceiptLogo?: Maybe<Scalars['String']>;
  oauthBody?: Maybe<Scalars['KeyValueStringArray']>;
  oauthBodyEncoding?: Maybe<OauthBodyEncoding>;
  partner: Partner;
  partnerId: Scalars['String'];
  serviceFeeDescription: Scalars['String'];
  transactionNoticeUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum ProductAuthenticationMode {
  Basic = 'BASIC',
  Oauth = 'OAUTH'
}

export type ProductCategory = {
  __typename?: 'ProductCategory';
  code: Scalars['String'];
  collectionType: CollectionType;
  id: Scalars['ID'];
  label: Scalars['String'];
  serviceOffer?: Maybe<ServiceOfferDto>;
};

export type ProductFilterDto = {
  active?: InputMaybe<Scalars['Boolean']>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  createdAtGte?: InputMaybe<Scalars['DateTime']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  partialDatamatrixProductId?: InputMaybe<Scalars['String']>;
  partnerIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductInputDto = {
  active: Scalars['Boolean'];
  category: PartnerProductCategoryDto;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromotionSettings = {
  __typename?: 'PromotionSettings';
  gainAmountInCents: Scalars['Float'];
  id: PromotionSettingsType;
  isActive: Scalars['Boolean'];
  referrerGainAmountInCents?: Maybe<Scalars['Float']>;
  requiredPaymentsCount: Scalars['Float'];
  requiredPaymentsMinimumAmountInCents: Scalars['Float'];
  requiredPaymentsSlidingDays: Scalars['Float'];
  retailerGainAmountInCents?: Maybe<Scalars['Float']>;
  slidingMonthLimit: Scalars['Float'];
  userLimit: Scalars['Float'];
};

export enum PromotionSettingsType {
  ReferrerPromotion = 'REFERRER_PROMOTION',
  RetailerPromotion = 'RETAILER_PROMOTION'
}

export type Query = {
  __typename?: 'Query';
  Admin: Admin;
  AppBasicContent: AppBasicContent;
  AppFaqContent: AppFaqContent;
  CarrouselImage: CarrouselImage;
  Cart: Cart;
  Client: Client;
  CustomerServiceInfo: CustomerServiceInfo;
  NirioBankWelcomePromotion: NirioBankWelcomePromotion;
  Partner: Partner;
  Payable: Payable;
  PaymentWorkflow: PaymentWorkflow;
  Product: Product;
  PromotionSettings: PromotionSettings;
  Retailer?: Maybe<Retailer>;
  _allAdminsMeta: CountDto;
  _allAppBasicContentsMeta: CountDto;
  _allCarrouselImagesMeta: CountDto;
  _allCartsMeta: CountDto;
  _allCessationRaWorkflowsMeta: CountDto;
  _allClientsMeta: CountDto;
  _allCustomerServiceInfosMeta: CountDto;
  _allNirioBankDebtsMeta: CountDto;
  _allNirioBankWelcomeCodesMeta: CountDto;
  _allNirioBankWelcomePromotionsMeta: CountDto;
  _allPartnersMeta: CountDto;
  _allPayablesMeta: CountDto;
  _allPaymentWorkflowsMeta: CountDto;
  _allProductsMeta: CountDto;
  _allRetailersMeta: CountDto;
  allAdmins: Array<Admin>;
  allAppBasicContents: Array<AppBasicContent>;
  allCarrouselImages: Array<CarrouselImage>;
  allCarts: Array<Cart>;
  allCessationRaWorkflows: Array<CessationRaWorkflow>;
  allClients: Array<Client>;
  allCustomerServiceInfos: Array<CustomerServiceInfo>;
  allFeatureFlags: FeatureFlags;
  allNirioBankDebts: Array<NirioBankDebt>;
  allNirioBankPromotionOffers: Array<NirioBankPromotionOffer>;
  allNirioBankWelcomeCodes: Array<NirioBankWelcomeCode>;
  allNirioBankWelcomePromotions: Array<NirioBankWelcomePromotion>;
  allPartners: Array<Partner>;
  allPayables: Array<Payable>;
  allPaymentWorkflows: Array<PaymentWorkflow>;
  allProducts: Array<Product>;
  allRetailers: Array<Retailer>;
  allZipCode: RetailerDepartmentsZipCodesDto;
  generateProductTransactionsExportLink: Scalars['String'];
  getAdminByEmail?: Maybe<Admin>;
  getAllPartnerOptions: Array<Option>;
  getAllPartners: Array<Partner>;
  getAllProductCategories: Array<ProductCategory>;
  getBeneficiaries: Array<NirioBankBeneficiaryDto>;
  getCessationRaReasons: Array<CraClosingReasonDto>;
  getLatestAppFaqContent: AppFaqContent;
  getMaintenanceSettings: MaintenanceSettingsDto;
  getPartnerByDatamatrixPartnerId?: Maybe<Partner>;
  getPartnerByName?: Maybe<Partner>;
  getProductByDatamatrixAndPartner?: Maybe<Product>;
  isMerchantIdValidForProduct: Scalars['Boolean'];
  kycWorkflowsWithCount: GetNirioUserKycWorkflowsWithCountDto;
  me: Admin;
  nirioBankDebtSum: Scalars['Float'];
  retailerMonitoringCount: RetailerMonitoringCountDto;
};


export type QueryAdminArgs = {
  id: Scalars['ID'];
};


export type QueryAppBasicContentArgs = {
  id: Scalars['ID'];
};


export type QueryAppFaqContentArgs = {
  id: Scalars['ID'];
};


export type QueryCarrouselImageArgs = {
  id: Scalars['ID'];
};


export type QueryCartArgs = {
  id: Scalars['ID'];
};


export type QueryClientArgs = {
  id: Scalars['String'];
};


export type QueryCustomerServiceInfoArgs = {
  id: Scalars['ID'];
};


export type QueryNirioBankWelcomePromotionArgs = {
  id: Scalars['String'];
};


export type QueryPartnerArgs = {
  id: Scalars['ID'];
};


export type QueryPayableArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentWorkflowArgs = {
  id: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionSettingsArgs = {
  id: Scalars['String'];
};


export type QueryRetailerArgs = {
  id: Scalars['String'];
};


export type Query_AllAdminsMetaArgs = {
  filter: AdminFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllCarrouselImagesMetaArgs = {
  filter: CarrouselImageFilterDto;
};


export type Query_AllCartsMetaArgs = {
  filter: CartFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllCessationRaWorkflowsMetaArgs = {
  filter: CessationRaFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllClientsMetaArgs = {
  filter: ClientFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllNirioBankDebtsMetaArgs = {
  filter: NirioBankDebtFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllNirioBankWelcomeCodesMetaArgs = {
  filter: NirioBankWelcomeCodeFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllNirioBankWelcomePromotionsMetaArgs = {
  filter: NirioBankWelcomePromotionFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllPartnersMetaArgs = {
  filter: PartnerFiltersDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllPayablesMetaArgs = {
  filter: PayableFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllPaymentWorkflowsMetaArgs = {
  filter: PaymentWorkflowFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllProductsMetaArgs = {
  filter: ProductFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type Query_AllRetailersMetaArgs = {
  filter: RetailerFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type QueryAllAdminsArgs = {
  filter: AdminFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllCarrouselImagesArgs = {
  filter: CarrouselImageFilterDto;
};


export type QueryAllCartsArgs = {
  filter: CartFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllCessationRaWorkflowsArgs = {
  filter: CessationRaFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllClientsArgs = {
  filter: ClientFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllNirioBankDebtsArgs = {
  filter: NirioBankDebtFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllNirioBankWelcomeCodesArgs = {
  filter: NirioBankWelcomeCodeFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllNirioBankWelcomePromotionsArgs = {
  filter: NirioBankWelcomePromotionFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllPartnersArgs = {
  filter: PartnerFiltersDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllPayablesArgs = {
  filter: PayableFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllPaymentWorkflowsArgs = {
  filter: PaymentWorkflowFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllProductsArgs = {
  filter: ProductFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryAllRetailersArgs = {
  filter: RetailerFilterDto;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryGenerateProductTransactionsExportLinkArgs = {
  datamatrixPartnerId: Scalars['String'];
  datamatrixProductId: Scalars['String'];
  endDate: Scalars['String'];
  format: TransactionsExportFormat;
  startDate: Scalars['String'];
};


export type QueryGetAdminByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetBeneficiariesArgs = {
  nirioUserId: Scalars['ID'];
};


export type QueryGetPartnerByDatamatrixPartnerIdArgs = {
  datamatrixPartnerId: Scalars['String'];
};


export type QueryGetPartnerByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetProductByDatamatrixAndPartnerArgs = {
  datamatrixProductId: Scalars['String'];
  partnerId: Scalars['String'];
};


export type QueryIsMerchantIdValidForProductArgs = {
  categoryId: Scalars['String'];
  cbsMerchantId: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
};


export type QueryKycWorkflowsWithCountArgs = {
  nirioUserId: Scalars['String'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};


export type QueryNirioBankDebtSumArgs = {
  filter: NirioBankDebtFilterDto;
};

export type RefreshAccessTokenResultError = {
  __typename?: 'RefreshAccessTokenResultError';
  code: AuthenticationErrors;
};

export type RefreshAccessTokenResultSuccess = {
  __typename?: 'RefreshAccessTokenResultSuccess';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Retailer = {
  __typename?: 'Retailer';
  acprAgentStatus?: Maybe<Scalars['String']>;
  acprFormationStatus?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  cbsAgentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  departmentCode: Scalars['String'];
  externalUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rdiAcprAgentCode?: Maybe<Scalars['String']>;
  rdiPointOfSaleCode: Scalars['String'];
  rdiRoleCode: Scalars['String'];
  reasonsForBlock: Array<Scalars['String']>;
  serviceOffers: Array<ServiceOfferDto>;
  status: RetailerStatus;
  velocity?: Maybe<RetailerVelocityDto>;
  zipCode: Scalars['String'];
};

export type RetailerDepartmentsZipCodesDto = {
  __typename?: 'RetailerDepartmentsZipCodesDto';
  departmentCodes: Array<Scalars['String']>;
  zipCodes: Array<Scalars['String']>;
};

export type RetailerFilterDto = {
  aboveVelocityThreshold?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  bonuses?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  penalties?: InputMaybe<Scalars['Boolean']>;
  rdiPointOfSaleCode?: InputMaybe<Scalars['String']>;
  rdiRoleCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<RetailerStatus>>;
};

export type RetailerMonitoringCountDto = {
  __typename?: 'RetailerMonitoringCountDto';
  aboveVelocityThreshold: Scalars['Int'];
  blocked: Scalars['Int'];
  bonuses: Scalars['Int'];
  penalties: Scalars['Int'];
};

export enum RetailerStatus {
  BlockedByRdi = 'BLOCKED_BY_RDI',
  BlockedManually = 'BLOCKED_MANUALLY',
  Closed = 'CLOSED',
  Open = 'OPEN',
  Pending = 'PENDING'
}

export type RetailerVelocityDto = {
  __typename?: 'RetailerVelocityDto';
  currentValueInCents: Scalars['Int'];
  limitValueInCents: Scalars['Int'];
};

export enum SendMagicLinkResult {
  Success = 'SUCCESS'
}

export type ServiceOfferDto = {
  __typename?: 'ServiceOfferDto';
  formattedId: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  productCategoryCodes?: Maybe<Scalars['String']>;
};

export type SigninAdminWithMagicLinkTokenError = {
  __typename?: 'SigninAdminWithMagicLinkTokenError';
  code: SigninAdminWithMagicLinkTokenErrors;
};

export enum SigninAdminWithMagicLinkTokenErrors {
  TokenExpired = 'TOKEN_EXPIRED'
}

export type SigninAdminWithMagicLinkTokenResult = SigninAdminWithMagicLinkTokenError | SigninAdminWithMagicLinkTokenSuccess;

export type SigninAdminWithMagicLinkTokenSuccess = {
  __typename?: 'SigninAdminWithMagicLinkTokenSuccess';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export enum TransactionsExportFormat {
  Csv = 'CSV',
  Seqens = 'SEQENS'
}

export type RefreshAccessTokenResult = RefreshAccessTokenResultError | RefreshAccessTokenResultSuccess;

export type AllCarrouselImagesQueryVariables = Exact<{
  filter: CarrouselImageFilterDto;
}>;


export type AllCarrouselImagesQuery = { __typename?: 'Query', items: Array<{ __typename?: 'CarrouselImage', id: string, imageBytes: string, name: string, description?: string | null, internalUrl?: InternalUrl | null, externalUrl?: string | null, appearanceOrder: number, offerType: OfferTypeEnum }> };

export type CreateCarrouselImageMutationVariables = Exact<{
  appearanceOrder: Scalars['String'];
  offerType: OfferTypeEnum;
  name: Scalars['String'];
  description: Scalars['String'];
  imageBytes: Scalars['String'];
  internalUrl?: InputMaybe<InternalUrl>;
  externalUrl?: InputMaybe<Scalars['String']>;
}>;


export type CreateCarrouselImageMutation = { __typename?: 'Mutation', createCarrouselImage: { __typename?: 'CarrouselImage', id: string, imageBytes: string, name: string, description?: string | null, internalUrl?: InternalUrl | null, externalUrl?: string | null, appearanceOrder: number, offerType: OfferTypeEnum } };

export type CreateNirioBankWelcomeCodeMultipleUsageMutationVariables = Exact<{
  code: Scalars['String'];
  maxClaimed: Scalars['Int'];
  promotionId: Scalars['String'];
}>;


export type CreateNirioBankWelcomeCodeMultipleUsageMutation = { __typename?: 'Mutation', createNirioBankWelcomeCodeMultipleUsage: { __typename?: 'CreateNirioBankWelcomeCodeError', codeErr: CreateNirioBankWelcomeCodeErrorCode } | { __typename?: 'NirioBankWelcomeCode', id: string, code: string, maxClaimed: number, promotionId: string, createdAt: any } };

export type DeleteCarrouselImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCarrouselImageMutation = { __typename?: 'Mutation', deleteCarrouselImage: { __typename?: 'CarrouselImage', id: string, imageBytes: string, name: string, description?: string | null, internalUrl?: InternalUrl | null, externalUrl?: string | null, appearanceOrder: number } };

export type GenerateProductTransactionsExportLinkQueryVariables = Exact<{
  datamatrixPartnerId: Scalars['String'];
  datamatrixProductId: Scalars['String'];
  format: TransactionsExportFormat;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GenerateProductTransactionsExportLinkQuery = { __typename?: 'Query', generateProductTransactionsExportLink: string };

export type GetAdminByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetAdminByEmailQuery = { __typename?: 'Query', getAdminByEmail?: { __typename?: 'Admin', id: string } | null };

export type GetAllNirioBankPromotionOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNirioBankPromotionOffersQuery = { __typename?: 'Query', allNirioBankPromotionOffers: Array<{ __typename?: 'NirioBankPromotionOffer', id: string, name: string }> };

export type GetAllPartnerOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPartnerOptionsQuery = { __typename?: 'Query', getAllPartnerOptions: Array<{ __typename?: 'Option', id: string, label: string }> };

export type GetAllPartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPartnersQuery = { __typename?: 'Query', getAllPartners: Array<{ __typename?: 'Partner', id: string, name: string }> };

export type GetAllProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductCategoriesQuery = { __typename?: 'Query', getAllProductCategories: Array<{ __typename?: 'ProductCategory', id: string, label: string, code: string, collectionType: CollectionType }> };

export type GetBeneficiariesQueryVariables = Exact<{
  nirioUserId: Scalars['ID'];
}>;


export type GetBeneficiariesQuery = { __typename?: 'Query', getBeneficiaries: Array<{ __typename?: 'NirioBankBeneficiaryDto', displayName: string, iban: string }> };

export type GetCessationRaReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCessationRaReasonsQuery = { __typename?: 'Query', getCessationRaReasons: Array<{ __typename?: 'CraClosingReasonDto', code: CessationRaReason, label: string, hasComment: boolean }> };

export type RetailerDepartmentAndZipCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type RetailerDepartmentAndZipCodeQuery = { __typename?: 'Query', allZipCode: { __typename?: 'RetailerDepartmentsZipCodesDto', zipCodes: Array<string>, departmentCodes: Array<string> } };

export type GetFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureFlagsQuery = { __typename?: 'Query', allFeatureFlags: { __typename?: 'FeatureFlags', neptuneSimulator: OnOff, operatorAuthorizationCheck: OnOff } };

export type GetLatestAppFaqContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestAppFaqContentQuery = { __typename?: 'Query', getLatestAppFaqContent: { __typename?: 'AppFaqContent', id: string, createdAt: any, content: { __typename?: 'AppFaqContentContentDto', title?: string | null, categories: Array<{ __typename?: 'AppFaqContentCategoryDto', title: string, description?: string | null, optionalIdentifier?: string | null, posts: Array<{ __typename?: 'AppFaqContentPostDto', title: string, subtitle?: string | null, content: string, optionalIdentifier?: string | null }> }> } } };

export type GetMaintenanceSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaintenanceSettingsQuery = { __typename?: 'Query', getMaintenanceSettings: { __typename?: 'MaintenanceSettingsDto', go: boolean, premio: boolean, message?: string | null } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'Admin', firstName: string, lastName: string, email: string } };

export type GetMyPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPermissionsQuery = { __typename?: 'Query', me: { __typename?: 'Admin', permissions: Array<PermissionDto> } };

export type GetNirioBankDebtIndicatorQueryVariables = Exact<{
  filter: NirioBankDebtFilterDto;
}>;


export type GetNirioBankDebtIndicatorQuery = { __typename?: 'Query', nirioBankDebtSum: number };

export type GetNirioUserKycWorkflowsQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
  nirioUserId: Scalars['String'];
}>;


export type GetNirioUserKycWorkflowsQuery = { __typename?: 'Query', kycWorkflowsWithCount: { __typename?: 'GetNirioUserKycWorkflowsWithCountDto', total: number, items: Array<{ __typename?: 'KycWorkflow', id: string, createdAt: any, cisDocumentId?: string | null, externalUrl?: string | null, stage: KycWorkflowStage, abortedReason?: KycWorkflowAbortedReason | null, isManual: boolean }> } };

export type GetPartnerByDatamatrixPartnerIdQueryVariables = Exact<{
  datamatrixPartnerId: Scalars['String'];
}>;


export type GetPartnerByDatamatrixPartnerIdQuery = { __typename?: 'Query', getPartnerByDatamatrixPartnerId?: { __typename?: 'Partner', id: string } | null };

export type GetPartnerByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetPartnerByNameQuery = { __typename?: 'Query', getPartnerByName?: { __typename?: 'Partner', id: string } | null };

export type GetProductByDatamatrixAndPartnerQueryVariables = Exact<{
  datamatrixProductId: Scalars['String'];
  partnerId: Scalars['String'];
}>;


export type GetProductByDatamatrixAndPartnerQuery = { __typename?: 'Query', getProductByDatamatrixAndPartner?: { __typename?: 'Product', id: string } | null };

export type GetRetailerMonitoringCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRetailerMonitoringCountQuery = { __typename?: 'Query', retailerMonitoringCount: { __typename?: 'RetailerMonitoringCountDto', aboveVelocityThreshold: number, blocked: number, bonuses: number, penalties: number } };

export type IsMerchantIdValidForProductQueryVariables = Exact<{
  cbsMerchantId: Scalars['String'];
  categoryId: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
}>;


export type IsMerchantIdValidForProductQuery = { __typename?: 'Query', isMerchantIdValidForProduct: boolean };

export type LogoutMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type LogoutMutation = { __typename?: 'Mutation', logout: string };

export type LoginRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type LoginRequestMutation = { __typename?: 'Mutation', sendMagicLink: SendMagicLinkResult };

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenMutation = { __typename?: 'Mutation', signinAdminWithMagicLinkToken: { __typename?: 'SigninAdminWithMagicLinkTokenError', code: SigninAdminWithMagicLinkTokenErrors } | { __typename?: 'SigninAdminWithMagicLinkTokenSuccess', accessToken: string, refreshToken: string } };

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshAccessTokenMutation = { __typename?: 'Mutation', refreshAccessToken: { __typename?: 'RefreshAccessTokenResultError', code: AuthenticationErrors } | { __typename?: 'RefreshAccessTokenResultSuccess', refreshToken: string, accessToken: string } };

export type UpdateAppBasicContentMutationVariables = Exact<{
  type: AppBasicContentType;
  content: AppBasicContentHtmlInput;
}>;


export type UpdateAppBasicContentMutation = { __typename?: 'Mutation', updateAppBasicContent: { __typename?: 'AppBasicContent', id: string, createdAt: any, type: AppContentType, content: { __typename?: 'AppBasicContentHtmlDto', title?: string | null, html: string } } };

export type UpdateAppFaqContentMutationVariables = Exact<{
  content: AppFaqContentContentInput;
}>;


export type UpdateAppFaqContentMutation = { __typename?: 'Mutation', updateAppFaqContent: { __typename?: 'AppFaqContent', id: string, createdAt: any, content: { __typename?: 'AppFaqContentContentDto', title?: string | null, categories: Array<{ __typename?: 'AppFaqContentCategoryDto', title: string, description?: string | null, optionalIdentifier?: string | null, posts: Array<{ __typename?: 'AppFaqContentPostDto', title: string, subtitle?: string | null, content: string, optionalIdentifier?: string | null }> }> } } };

export type UpdateMaintenanceSettingsMutationVariables = Exact<{
  go: Scalars['Boolean'];
  premio: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
}>;


export type UpdateMaintenanceSettingsMutation = { __typename?: 'Mutation', updateMaintenanceSettings: { __typename?: 'MaintenanceSettingsDto', go: boolean, premio: boolean, message?: string | null } };

export type UpdatePromotionSettingsReferrerMutationVariables = Exact<{
  gainAmountInCents: Scalars['Float'];
  id: PromotionSettingsType;
  isActive: Scalars['Boolean'];
  requiredPaymentsCount: Scalars['Float'];
  requiredPaymentsMinimumAmountInCents: Scalars['Float'];
  requiredPaymentsSlidingDays: Scalars['Float'];
  referrerGainAmountInCents?: InputMaybe<Scalars['Float']>;
  slidingMonthLimit: Scalars['Float'];
  userLimit: Scalars['Float'];
}>;


export type UpdatePromotionSettingsReferrerMutation = { __typename?: 'Mutation', updatePromotionSettingsReferrer: { __typename: 'PromotionSettings', gainAmountInCents: number, id: PromotionSettingsType, isActive: boolean, referrerGainAmountInCents?: number | null, requiredPaymentsCount: number, requiredPaymentsMinimumAmountInCents: number, requiredPaymentsSlidingDays: number, retailerGainAmountInCents?: number | null, slidingMonthLimit: number, userLimit: number } };

export type UpdatePromotionSettingsRetailerMutationVariables = Exact<{
  gainAmountInCents: Scalars['Float'];
  id: PromotionSettingsType;
  isActive: Scalars['Boolean'];
  requiredPaymentsCount: Scalars['Float'];
  requiredPaymentsMinimumAmountInCents: Scalars['Float'];
  requiredPaymentsSlidingDays: Scalars['Float'];
  retailerGainAmountInCents?: InputMaybe<Scalars['Float']>;
  slidingMonthLimit: Scalars['Float'];
  userLimit: Scalars['Float'];
}>;


export type UpdatePromotionSettingsRetailerMutation = { __typename?: 'Mutation', updatePromotionSettingsRetailer: { __typename: 'PromotionSettings', gainAmountInCents: number, id: PromotionSettingsType, isActive: boolean, retailerGainAmountInCents?: number | null, requiredPaymentsCount: number, requiredPaymentsMinimumAmountInCents: number, requiredPaymentsSlidingDays: number, slidingMonthLimit: number, userLimit: number } };


export const AllCarrouselImagesDocument = gql`
    query AllCarrouselImages($filter: CarrouselImageFilterDto!) {
  items: allCarrouselImages(filter: $filter) {
    id
    imageBytes
    name
    description
    internalUrl
    externalUrl
    appearanceOrder
    offerType
  }
}
    `;

/**
 * __useAllCarrouselImagesQuery__
 *
 * To run a query within a React component, call `useAllCarrouselImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCarrouselImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCarrouselImagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllCarrouselImagesQuery(baseOptions: Apollo.QueryHookOptions<AllCarrouselImagesQuery, AllCarrouselImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCarrouselImagesQuery, AllCarrouselImagesQueryVariables>(AllCarrouselImagesDocument, options);
      }
export function useAllCarrouselImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCarrouselImagesQuery, AllCarrouselImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCarrouselImagesQuery, AllCarrouselImagesQueryVariables>(AllCarrouselImagesDocument, options);
        }
export type AllCarrouselImagesQueryHookResult = ReturnType<typeof useAllCarrouselImagesQuery>;
export type AllCarrouselImagesLazyQueryHookResult = ReturnType<typeof useAllCarrouselImagesLazyQuery>;
export type AllCarrouselImagesQueryResult = Apollo.QueryResult<AllCarrouselImagesQuery, AllCarrouselImagesQueryVariables>;
export const CreateCarrouselImageDocument = gql`
    mutation CreateCarrouselImage($appearanceOrder: String!, $offerType: OfferTypeEnum!, $name: String!, $description: String!, $imageBytes: String!, $internalUrl: InternalUrl, $externalUrl: String) {
  createCarrouselImage(
    internalUrl: $internalUrl
    externalUrl: $externalUrl
    appearanceOrder: $appearanceOrder
    offerType: $offerType
    name: $name
    description: $description
    imageBytes: $imageBytes
  ) {
    id
    imageBytes
    name
    description
    internalUrl
    externalUrl
    appearanceOrder
    offerType
  }
}
    `;
export type CreateCarrouselImageMutationFn = Apollo.MutationFunction<CreateCarrouselImageMutation, CreateCarrouselImageMutationVariables>;

/**
 * __useCreateCarrouselImageMutation__
 *
 * To run a mutation, you first call `useCreateCarrouselImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrouselImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarrouselImageMutation, { data, loading, error }] = useCreateCarrouselImageMutation({
 *   variables: {
 *      appearanceOrder: // value for 'appearanceOrder'
 *      offerType: // value for 'offerType'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      imageBytes: // value for 'imageBytes'
 *      internalUrl: // value for 'internalUrl'
 *      externalUrl: // value for 'externalUrl'
 *   },
 * });
 */
export function useCreateCarrouselImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarrouselImageMutation, CreateCarrouselImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCarrouselImageMutation, CreateCarrouselImageMutationVariables>(CreateCarrouselImageDocument, options);
      }
export type CreateCarrouselImageMutationHookResult = ReturnType<typeof useCreateCarrouselImageMutation>;
export type CreateCarrouselImageMutationResult = Apollo.MutationResult<CreateCarrouselImageMutation>;
export type CreateCarrouselImageMutationOptions = Apollo.BaseMutationOptions<CreateCarrouselImageMutation, CreateCarrouselImageMutationVariables>;
export const CreateNirioBankWelcomeCodeMultipleUsageDocument = gql`
    mutation createNirioBankWelcomeCodeMultipleUsage($code: String!, $maxClaimed: Int!, $promotionId: String!) {
  createNirioBankWelcomeCodeMultipleUsage(
    code: $code
    maxClaimed: $maxClaimed
    promotionId: $promotionId
  ) {
    ... on CreateNirioBankWelcomeCodeError {
      codeErr
    }
    ... on NirioBankWelcomeCode {
      id
      code
      maxClaimed
      promotionId
      createdAt
    }
  }
}
    `;
export type CreateNirioBankWelcomeCodeMultipleUsageMutationFn = Apollo.MutationFunction<CreateNirioBankWelcomeCodeMultipleUsageMutation, CreateNirioBankWelcomeCodeMultipleUsageMutationVariables>;

/**
 * __useCreateNirioBankWelcomeCodeMultipleUsageMutation__
 *
 * To run a mutation, you first call `useCreateNirioBankWelcomeCodeMultipleUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNirioBankWelcomeCodeMultipleUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNirioBankWelcomeCodeMultipleUsageMutation, { data, loading, error }] = useCreateNirioBankWelcomeCodeMultipleUsageMutation({
 *   variables: {
 *      code: // value for 'code'
 *      maxClaimed: // value for 'maxClaimed'
 *      promotionId: // value for 'promotionId'
 *   },
 * });
 */
export function useCreateNirioBankWelcomeCodeMultipleUsageMutation(baseOptions?: Apollo.MutationHookOptions<CreateNirioBankWelcomeCodeMultipleUsageMutation, CreateNirioBankWelcomeCodeMultipleUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNirioBankWelcomeCodeMultipleUsageMutation, CreateNirioBankWelcomeCodeMultipleUsageMutationVariables>(CreateNirioBankWelcomeCodeMultipleUsageDocument, options);
      }
export type CreateNirioBankWelcomeCodeMultipleUsageMutationHookResult = ReturnType<typeof useCreateNirioBankWelcomeCodeMultipleUsageMutation>;
export type CreateNirioBankWelcomeCodeMultipleUsageMutationResult = Apollo.MutationResult<CreateNirioBankWelcomeCodeMultipleUsageMutation>;
export type CreateNirioBankWelcomeCodeMultipleUsageMutationOptions = Apollo.BaseMutationOptions<CreateNirioBankWelcomeCodeMultipleUsageMutation, CreateNirioBankWelcomeCodeMultipleUsageMutationVariables>;
export const DeleteCarrouselImageDocument = gql`
    mutation DeleteCarrouselImage($id: ID!) {
  deleteCarrouselImage(id: $id) {
    id
    imageBytes
    name
    description
    internalUrl
    externalUrl
    appearanceOrder
  }
}
    `;
export type DeleteCarrouselImageMutationFn = Apollo.MutationFunction<DeleteCarrouselImageMutation, DeleteCarrouselImageMutationVariables>;

/**
 * __useDeleteCarrouselImageMutation__
 *
 * To run a mutation, you first call `useDeleteCarrouselImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrouselImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarrouselImageMutation, { data, loading, error }] = useDeleteCarrouselImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarrouselImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCarrouselImageMutation, DeleteCarrouselImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCarrouselImageMutation, DeleteCarrouselImageMutationVariables>(DeleteCarrouselImageDocument, options);
      }
export type DeleteCarrouselImageMutationHookResult = ReturnType<typeof useDeleteCarrouselImageMutation>;
export type DeleteCarrouselImageMutationResult = Apollo.MutationResult<DeleteCarrouselImageMutation>;
export type DeleteCarrouselImageMutationOptions = Apollo.BaseMutationOptions<DeleteCarrouselImageMutation, DeleteCarrouselImageMutationVariables>;
export const GenerateProductTransactionsExportLinkDocument = gql`
    query GenerateProductTransactionsExportLink($datamatrixPartnerId: String!, $datamatrixProductId: String!, $format: TransactionsExportFormat!, $startDate: String!, $endDate: String!) {
  generateProductTransactionsExportLink(
    datamatrixPartnerId: $datamatrixPartnerId
    datamatrixProductId: $datamatrixProductId
    format: $format
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useGenerateProductTransactionsExportLinkQuery__
 *
 * To run a query within a React component, call `useGenerateProductTransactionsExportLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateProductTransactionsExportLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateProductTransactionsExportLinkQuery({
 *   variables: {
 *      datamatrixPartnerId: // value for 'datamatrixPartnerId'
 *      datamatrixProductId: // value for 'datamatrixProductId'
 *      format: // value for 'format'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGenerateProductTransactionsExportLinkQuery(baseOptions: Apollo.QueryHookOptions<GenerateProductTransactionsExportLinkQuery, GenerateProductTransactionsExportLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateProductTransactionsExportLinkQuery, GenerateProductTransactionsExportLinkQueryVariables>(GenerateProductTransactionsExportLinkDocument, options);
      }
export function useGenerateProductTransactionsExportLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateProductTransactionsExportLinkQuery, GenerateProductTransactionsExportLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateProductTransactionsExportLinkQuery, GenerateProductTransactionsExportLinkQueryVariables>(GenerateProductTransactionsExportLinkDocument, options);
        }
export type GenerateProductTransactionsExportLinkQueryHookResult = ReturnType<typeof useGenerateProductTransactionsExportLinkQuery>;
export type GenerateProductTransactionsExportLinkLazyQueryHookResult = ReturnType<typeof useGenerateProductTransactionsExportLinkLazyQuery>;
export type GenerateProductTransactionsExportLinkQueryResult = Apollo.QueryResult<GenerateProductTransactionsExportLinkQuery, GenerateProductTransactionsExportLinkQueryVariables>;
export const GetAdminByEmailDocument = gql`
    query GetAdminByEmail($email: String!) {
  getAdminByEmail(email: $email) {
    id
  }
}
    `;

/**
 * __useGetAdminByEmailQuery__
 *
 * To run a query within a React component, call `useGetAdminByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAdminByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetAdminByEmailQuery, GetAdminByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminByEmailQuery, GetAdminByEmailQueryVariables>(GetAdminByEmailDocument, options);
      }
export function useGetAdminByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminByEmailQuery, GetAdminByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminByEmailQuery, GetAdminByEmailQueryVariables>(GetAdminByEmailDocument, options);
        }
export type GetAdminByEmailQueryHookResult = ReturnType<typeof useGetAdminByEmailQuery>;
export type GetAdminByEmailLazyQueryHookResult = ReturnType<typeof useGetAdminByEmailLazyQuery>;
export type GetAdminByEmailQueryResult = Apollo.QueryResult<GetAdminByEmailQuery, GetAdminByEmailQueryVariables>;
export const GetAllNirioBankPromotionOffersDocument = gql`
    query GetAllNirioBankPromotionOffers {
  allNirioBankPromotionOffers {
    id
    name
  }
}
    `;

/**
 * __useGetAllNirioBankPromotionOffersQuery__
 *
 * To run a query within a React component, call `useGetAllNirioBankPromotionOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNirioBankPromotionOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNirioBankPromotionOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNirioBankPromotionOffersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNirioBankPromotionOffersQuery, GetAllNirioBankPromotionOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNirioBankPromotionOffersQuery, GetAllNirioBankPromotionOffersQueryVariables>(GetAllNirioBankPromotionOffersDocument, options);
      }
export function useGetAllNirioBankPromotionOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNirioBankPromotionOffersQuery, GetAllNirioBankPromotionOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNirioBankPromotionOffersQuery, GetAllNirioBankPromotionOffersQueryVariables>(GetAllNirioBankPromotionOffersDocument, options);
        }
export type GetAllNirioBankPromotionOffersQueryHookResult = ReturnType<typeof useGetAllNirioBankPromotionOffersQuery>;
export type GetAllNirioBankPromotionOffersLazyQueryHookResult = ReturnType<typeof useGetAllNirioBankPromotionOffersLazyQuery>;
export type GetAllNirioBankPromotionOffersQueryResult = Apollo.QueryResult<GetAllNirioBankPromotionOffersQuery, GetAllNirioBankPromotionOffersQueryVariables>;
export const GetAllPartnerOptionsDocument = gql`
    query GetAllPartnerOptions {
  getAllPartnerOptions {
    id
    label
  }
}
    `;

/**
 * __useGetAllPartnerOptionsQuery__
 *
 * To run a query within a React component, call `useGetAllPartnerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPartnerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPartnerOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPartnerOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPartnerOptionsQuery, GetAllPartnerOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPartnerOptionsQuery, GetAllPartnerOptionsQueryVariables>(GetAllPartnerOptionsDocument, options);
      }
export function useGetAllPartnerOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPartnerOptionsQuery, GetAllPartnerOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPartnerOptionsQuery, GetAllPartnerOptionsQueryVariables>(GetAllPartnerOptionsDocument, options);
        }
export type GetAllPartnerOptionsQueryHookResult = ReturnType<typeof useGetAllPartnerOptionsQuery>;
export type GetAllPartnerOptionsLazyQueryHookResult = ReturnType<typeof useGetAllPartnerOptionsLazyQuery>;
export type GetAllPartnerOptionsQueryResult = Apollo.QueryResult<GetAllPartnerOptionsQuery, GetAllPartnerOptionsQueryVariables>;
export const GetAllPartnersDocument = gql`
    query GetAllPartners {
  getAllPartners {
    id
    name
  }
}
    `;

/**
 * __useGetAllPartnersQuery__
 *
 * To run a query within a React component, call `useGetAllPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPartnersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPartnersQuery, GetAllPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPartnersQuery, GetAllPartnersQueryVariables>(GetAllPartnersDocument, options);
      }
export function useGetAllPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPartnersQuery, GetAllPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPartnersQuery, GetAllPartnersQueryVariables>(GetAllPartnersDocument, options);
        }
export type GetAllPartnersQueryHookResult = ReturnType<typeof useGetAllPartnersQuery>;
export type GetAllPartnersLazyQueryHookResult = ReturnType<typeof useGetAllPartnersLazyQuery>;
export type GetAllPartnersQueryResult = Apollo.QueryResult<GetAllPartnersQuery, GetAllPartnersQueryVariables>;
export const GetAllProductCategoriesDocument = gql`
    query GetAllProductCategories {
  getAllProductCategories {
    id
    label
    code
    collectionType
  }
}
    `;

/**
 * __useGetAllProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>(GetAllProductCategoriesDocument, options);
      }
export function useGetAllProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>(GetAllProductCategoriesDocument, options);
        }
export type GetAllProductCategoriesQueryHookResult = ReturnType<typeof useGetAllProductCategoriesQuery>;
export type GetAllProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllProductCategoriesLazyQuery>;
export type GetAllProductCategoriesQueryResult = Apollo.QueryResult<GetAllProductCategoriesQuery, GetAllProductCategoriesQueryVariables>;
export const GetBeneficiariesDocument = gql`
    query GetBeneficiaries($nirioUserId: ID!) {
  getBeneficiaries(nirioUserId: $nirioUserId) {
    displayName
    iban
  }
}
    `;

/**
 * __useGetBeneficiariesQuery__
 *
 * To run a query within a React component, call `useGetBeneficiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeneficiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeneficiariesQuery({
 *   variables: {
 *      nirioUserId: // value for 'nirioUserId'
 *   },
 * });
 */
export function useGetBeneficiariesQuery(baseOptions: Apollo.QueryHookOptions<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>(GetBeneficiariesDocument, options);
      }
export function useGetBeneficiariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>(GetBeneficiariesDocument, options);
        }
export type GetBeneficiariesQueryHookResult = ReturnType<typeof useGetBeneficiariesQuery>;
export type GetBeneficiariesLazyQueryHookResult = ReturnType<typeof useGetBeneficiariesLazyQuery>;
export type GetBeneficiariesQueryResult = Apollo.QueryResult<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>;
export const GetCessationRaReasonsDocument = gql`
    query GetCessationRaReasons {
  getCessationRaReasons {
    code
    label
    hasComment
  }
}
    `;

/**
 * __useGetCessationRaReasonsQuery__
 *
 * To run a query within a React component, call `useGetCessationRaReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCessationRaReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCessationRaReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCessationRaReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetCessationRaReasonsQuery, GetCessationRaReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCessationRaReasonsQuery, GetCessationRaReasonsQueryVariables>(GetCessationRaReasonsDocument, options);
      }
export function useGetCessationRaReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCessationRaReasonsQuery, GetCessationRaReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCessationRaReasonsQuery, GetCessationRaReasonsQueryVariables>(GetCessationRaReasonsDocument, options);
        }
export type GetCessationRaReasonsQueryHookResult = ReturnType<typeof useGetCessationRaReasonsQuery>;
export type GetCessationRaReasonsLazyQueryHookResult = ReturnType<typeof useGetCessationRaReasonsLazyQuery>;
export type GetCessationRaReasonsQueryResult = Apollo.QueryResult<GetCessationRaReasonsQuery, GetCessationRaReasonsQueryVariables>;
export const RetailerDepartmentAndZipCodeDocument = gql`
    query RetailerDepartmentAndZipCode {
  allZipCode {
    zipCodes
    departmentCodes
  }
}
    `;

/**
 * __useRetailerDepartmentAndZipCodeQuery__
 *
 * To run a query within a React component, call `useRetailerDepartmentAndZipCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailerDepartmentAndZipCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailerDepartmentAndZipCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetailerDepartmentAndZipCodeQuery(baseOptions?: Apollo.QueryHookOptions<RetailerDepartmentAndZipCodeQuery, RetailerDepartmentAndZipCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailerDepartmentAndZipCodeQuery, RetailerDepartmentAndZipCodeQueryVariables>(RetailerDepartmentAndZipCodeDocument, options);
      }
export function useRetailerDepartmentAndZipCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailerDepartmentAndZipCodeQuery, RetailerDepartmentAndZipCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailerDepartmentAndZipCodeQuery, RetailerDepartmentAndZipCodeQueryVariables>(RetailerDepartmentAndZipCodeDocument, options);
        }
export type RetailerDepartmentAndZipCodeQueryHookResult = ReturnType<typeof useRetailerDepartmentAndZipCodeQuery>;
export type RetailerDepartmentAndZipCodeLazyQueryHookResult = ReturnType<typeof useRetailerDepartmentAndZipCodeLazyQuery>;
export type RetailerDepartmentAndZipCodeQueryResult = Apollo.QueryResult<RetailerDepartmentAndZipCodeQuery, RetailerDepartmentAndZipCodeQueryVariables>;
export const GetFeatureFlagsDocument = gql`
    query getFeatureFlags {
  allFeatureFlags {
    neptuneSimulator
    operatorAuthorizationCheck
  }
}
    `;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
      }
export function useGetFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
        }
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>;
export const GetLatestAppFaqContentDocument = gql`
    query GetLatestAppFaqContent {
  getLatestAppFaqContent {
    id
    createdAt
    content {
      title
      categories {
        title
        description
        optionalIdentifier
        posts {
          title
          subtitle
          content
          optionalIdentifier
        }
      }
    }
  }
}
    `;

/**
 * __useGetLatestAppFaqContentQuery__
 *
 * To run a query within a React component, call `useGetLatestAppFaqContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAppFaqContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAppFaqContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestAppFaqContentQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>(GetLatestAppFaqContentDocument, options);
      }
export function useGetLatestAppFaqContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>(GetLatestAppFaqContentDocument, options);
        }
export type GetLatestAppFaqContentQueryHookResult = ReturnType<typeof useGetLatestAppFaqContentQuery>;
export type GetLatestAppFaqContentLazyQueryHookResult = ReturnType<typeof useGetLatestAppFaqContentLazyQuery>;
export type GetLatestAppFaqContentQueryResult = Apollo.QueryResult<GetLatestAppFaqContentQuery, GetLatestAppFaqContentQueryVariables>;
export const GetMaintenanceSettingsDocument = gql`
    query getMaintenanceSettings {
  getMaintenanceSettings {
    go
    premio
    message
  }
}
    `;

/**
 * __useGetMaintenanceSettingsQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaintenanceSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetMaintenanceSettingsQuery, GetMaintenanceSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaintenanceSettingsQuery, GetMaintenanceSettingsQueryVariables>(GetMaintenanceSettingsDocument, options);
      }
export function useGetMaintenanceSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaintenanceSettingsQuery, GetMaintenanceSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaintenanceSettingsQuery, GetMaintenanceSettingsQueryVariables>(GetMaintenanceSettingsDocument, options);
        }
export type GetMaintenanceSettingsQueryHookResult = ReturnType<typeof useGetMaintenanceSettingsQuery>;
export type GetMaintenanceSettingsLazyQueryHookResult = ReturnType<typeof useGetMaintenanceSettingsLazyQuery>;
export type GetMaintenanceSettingsQueryResult = Apollo.QueryResult<GetMaintenanceSettingsQuery, GetMaintenanceSettingsQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMyPermissionsDocument = gql`
    query GetMyPermissions {
  me {
    permissions
  }
}
    `;

/**
 * __useGetMyPermissionsQuery__
 *
 * To run a query within a React component, call `useGetMyPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>(GetMyPermissionsDocument, options);
      }
export function useGetMyPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>(GetMyPermissionsDocument, options);
        }
export type GetMyPermissionsQueryHookResult = ReturnType<typeof useGetMyPermissionsQuery>;
export type GetMyPermissionsLazyQueryHookResult = ReturnType<typeof useGetMyPermissionsLazyQuery>;
export type GetMyPermissionsQueryResult = Apollo.QueryResult<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>;
export const GetNirioBankDebtIndicatorDocument = gql`
    query GetNirioBankDebtIndicator($filter: NirioBankDebtFilterDto!) {
  nirioBankDebtSum(filter: $filter)
}
    `;

/**
 * __useGetNirioBankDebtIndicatorQuery__
 *
 * To run a query within a React component, call `useGetNirioBankDebtIndicatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNirioBankDebtIndicatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNirioBankDebtIndicatorQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetNirioBankDebtIndicatorQuery(baseOptions: Apollo.QueryHookOptions<GetNirioBankDebtIndicatorQuery, GetNirioBankDebtIndicatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNirioBankDebtIndicatorQuery, GetNirioBankDebtIndicatorQueryVariables>(GetNirioBankDebtIndicatorDocument, options);
      }
export function useGetNirioBankDebtIndicatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNirioBankDebtIndicatorQuery, GetNirioBankDebtIndicatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNirioBankDebtIndicatorQuery, GetNirioBankDebtIndicatorQueryVariables>(GetNirioBankDebtIndicatorDocument, options);
        }
export type GetNirioBankDebtIndicatorQueryHookResult = ReturnType<typeof useGetNirioBankDebtIndicatorQuery>;
export type GetNirioBankDebtIndicatorLazyQueryHookResult = ReturnType<typeof useGetNirioBankDebtIndicatorLazyQuery>;
export type GetNirioBankDebtIndicatorQueryResult = Apollo.QueryResult<GetNirioBankDebtIndicatorQuery, GetNirioBankDebtIndicatorQueryVariables>;
export const GetNirioUserKycWorkflowsDocument = gql`
    query getNirioUserKycWorkflows($page: Int!, $perPage: Int!, $sortField: String!, $sortOrder: String!, $nirioUserId: String!) {
  kycWorkflowsWithCount(
    page: $page
    perPage: $perPage
    sortField: $sortField
    sortOrder: $sortOrder
    nirioUserId: $nirioUserId
  ) {
    items {
      id
      createdAt
      cisDocumentId
      externalUrl
      stage
      abortedReason
      isManual
    }
    total
  }
}
    `;

/**
 * __useGetNirioUserKycWorkflowsQuery__
 *
 * To run a query within a React component, call `useGetNirioUserKycWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNirioUserKycWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNirioUserKycWorkflowsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      nirioUserId: // value for 'nirioUserId'
 *   },
 * });
 */
export function useGetNirioUserKycWorkflowsQuery(baseOptions: Apollo.QueryHookOptions<GetNirioUserKycWorkflowsQuery, GetNirioUserKycWorkflowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNirioUserKycWorkflowsQuery, GetNirioUserKycWorkflowsQueryVariables>(GetNirioUserKycWorkflowsDocument, options);
      }
export function useGetNirioUserKycWorkflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNirioUserKycWorkflowsQuery, GetNirioUserKycWorkflowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNirioUserKycWorkflowsQuery, GetNirioUserKycWorkflowsQueryVariables>(GetNirioUserKycWorkflowsDocument, options);
        }
export type GetNirioUserKycWorkflowsQueryHookResult = ReturnType<typeof useGetNirioUserKycWorkflowsQuery>;
export type GetNirioUserKycWorkflowsLazyQueryHookResult = ReturnType<typeof useGetNirioUserKycWorkflowsLazyQuery>;
export type GetNirioUserKycWorkflowsQueryResult = Apollo.QueryResult<GetNirioUserKycWorkflowsQuery, GetNirioUserKycWorkflowsQueryVariables>;
export const GetPartnerByDatamatrixPartnerIdDocument = gql`
    query GetPartnerByDatamatrixPartnerId($datamatrixPartnerId: String!) {
  getPartnerByDatamatrixPartnerId(datamatrixPartnerId: $datamatrixPartnerId) {
    id
  }
}
    `;

/**
 * __useGetPartnerByDatamatrixPartnerIdQuery__
 *
 * To run a query within a React component, call `useGetPartnerByDatamatrixPartnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerByDatamatrixPartnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerByDatamatrixPartnerIdQuery({
 *   variables: {
 *      datamatrixPartnerId: // value for 'datamatrixPartnerId'
 *   },
 * });
 */
export function useGetPartnerByDatamatrixPartnerIdQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerByDatamatrixPartnerIdQuery, GetPartnerByDatamatrixPartnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerByDatamatrixPartnerIdQuery, GetPartnerByDatamatrixPartnerIdQueryVariables>(GetPartnerByDatamatrixPartnerIdDocument, options);
      }
export function useGetPartnerByDatamatrixPartnerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerByDatamatrixPartnerIdQuery, GetPartnerByDatamatrixPartnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerByDatamatrixPartnerIdQuery, GetPartnerByDatamatrixPartnerIdQueryVariables>(GetPartnerByDatamatrixPartnerIdDocument, options);
        }
export type GetPartnerByDatamatrixPartnerIdQueryHookResult = ReturnType<typeof useGetPartnerByDatamatrixPartnerIdQuery>;
export type GetPartnerByDatamatrixPartnerIdLazyQueryHookResult = ReturnType<typeof useGetPartnerByDatamatrixPartnerIdLazyQuery>;
export type GetPartnerByDatamatrixPartnerIdQueryResult = Apollo.QueryResult<GetPartnerByDatamatrixPartnerIdQuery, GetPartnerByDatamatrixPartnerIdQueryVariables>;
export const GetPartnerByNameDocument = gql`
    query GetPartnerByName($name: String!) {
  getPartnerByName(name: $name) {
    id
  }
}
    `;

/**
 * __useGetPartnerByNameQuery__
 *
 * To run a query within a React component, call `useGetPartnerByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetPartnerByNameQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerByNameQuery, GetPartnerByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerByNameQuery, GetPartnerByNameQueryVariables>(GetPartnerByNameDocument, options);
      }
export function useGetPartnerByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerByNameQuery, GetPartnerByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerByNameQuery, GetPartnerByNameQueryVariables>(GetPartnerByNameDocument, options);
        }
export type GetPartnerByNameQueryHookResult = ReturnType<typeof useGetPartnerByNameQuery>;
export type GetPartnerByNameLazyQueryHookResult = ReturnType<typeof useGetPartnerByNameLazyQuery>;
export type GetPartnerByNameQueryResult = Apollo.QueryResult<GetPartnerByNameQuery, GetPartnerByNameQueryVariables>;
export const GetProductByDatamatrixAndPartnerDocument = gql`
    query GetProductByDatamatrixAndPartner($datamatrixProductId: String!, $partnerId: String!) {
  getProductByDatamatrixAndPartner(
    datamatrixProductId: $datamatrixProductId
    partnerId: $partnerId
  ) {
    id
  }
}
    `;

/**
 * __useGetProductByDatamatrixAndPartnerQuery__
 *
 * To run a query within a React component, call `useGetProductByDatamatrixAndPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByDatamatrixAndPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByDatamatrixAndPartnerQuery({
 *   variables: {
 *      datamatrixProductId: // value for 'datamatrixProductId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetProductByDatamatrixAndPartnerQuery(baseOptions: Apollo.QueryHookOptions<GetProductByDatamatrixAndPartnerQuery, GetProductByDatamatrixAndPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductByDatamatrixAndPartnerQuery, GetProductByDatamatrixAndPartnerQueryVariables>(GetProductByDatamatrixAndPartnerDocument, options);
      }
export function useGetProductByDatamatrixAndPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductByDatamatrixAndPartnerQuery, GetProductByDatamatrixAndPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductByDatamatrixAndPartnerQuery, GetProductByDatamatrixAndPartnerQueryVariables>(GetProductByDatamatrixAndPartnerDocument, options);
        }
export type GetProductByDatamatrixAndPartnerQueryHookResult = ReturnType<typeof useGetProductByDatamatrixAndPartnerQuery>;
export type GetProductByDatamatrixAndPartnerLazyQueryHookResult = ReturnType<typeof useGetProductByDatamatrixAndPartnerLazyQuery>;
export type GetProductByDatamatrixAndPartnerQueryResult = Apollo.QueryResult<GetProductByDatamatrixAndPartnerQuery, GetProductByDatamatrixAndPartnerQueryVariables>;
export const GetRetailerMonitoringCountDocument = gql`
    query GetRetailerMonitoringCount {
  retailerMonitoringCount {
    aboveVelocityThreshold
    blocked
    bonuses
    penalties
  }
}
    `;

/**
 * __useGetRetailerMonitoringCountQuery__
 *
 * To run a query within a React component, call `useGetRetailerMonitoringCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailerMonitoringCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailerMonitoringCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRetailerMonitoringCountQuery(baseOptions?: Apollo.QueryHookOptions<GetRetailerMonitoringCountQuery, GetRetailerMonitoringCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRetailerMonitoringCountQuery, GetRetailerMonitoringCountQueryVariables>(GetRetailerMonitoringCountDocument, options);
      }
export function useGetRetailerMonitoringCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRetailerMonitoringCountQuery, GetRetailerMonitoringCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRetailerMonitoringCountQuery, GetRetailerMonitoringCountQueryVariables>(GetRetailerMonitoringCountDocument, options);
        }
export type GetRetailerMonitoringCountQueryHookResult = ReturnType<typeof useGetRetailerMonitoringCountQuery>;
export type GetRetailerMonitoringCountLazyQueryHookResult = ReturnType<typeof useGetRetailerMonitoringCountLazyQuery>;
export type GetRetailerMonitoringCountQueryResult = Apollo.QueryResult<GetRetailerMonitoringCountQuery, GetRetailerMonitoringCountQueryVariables>;
export const IsMerchantIdValidForProductDocument = gql`
    query isMerchantIdValidForProduct($cbsMerchantId: String!, $categoryId: String!, $productId: String) {
  isMerchantIdValidForProduct(
    cbsMerchantId: $cbsMerchantId
    categoryId: $categoryId
    productId: $productId
  )
}
    `;

/**
 * __useIsMerchantIdValidForProductQuery__
 *
 * To run a query within a React component, call `useIsMerchantIdValidForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsMerchantIdValidForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsMerchantIdValidForProductQuery({
 *   variables: {
 *      cbsMerchantId: // value for 'cbsMerchantId'
 *      categoryId: // value for 'categoryId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useIsMerchantIdValidForProductQuery(baseOptions: Apollo.QueryHookOptions<IsMerchantIdValidForProductQuery, IsMerchantIdValidForProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsMerchantIdValidForProductQuery, IsMerchantIdValidForProductQueryVariables>(IsMerchantIdValidForProductDocument, options);
      }
export function useIsMerchantIdValidForProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsMerchantIdValidForProductQuery, IsMerchantIdValidForProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsMerchantIdValidForProductQuery, IsMerchantIdValidForProductQueryVariables>(IsMerchantIdValidForProductDocument, options);
        }
export type IsMerchantIdValidForProductQueryHookResult = ReturnType<typeof useIsMerchantIdValidForProductQuery>;
export type IsMerchantIdValidForProductLazyQueryHookResult = ReturnType<typeof useIsMerchantIdValidForProductLazyQuery>;
export type IsMerchantIdValidForProductQueryResult = Apollo.QueryResult<IsMerchantIdValidForProductQuery, IsMerchantIdValidForProductQueryVariables>;
export const LogoutDocument = gql`
    mutation logout($refreshToken: String!) {
  logout(refreshToken: $refreshToken)
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LoginRequestDocument = gql`
    mutation loginRequest($email: String!) {
  sendMagicLink(email: $email)
}
    `;
export type LoginRequestMutationFn = Apollo.MutationFunction<LoginRequestMutation, LoginRequestMutationVariables>;

/**
 * __useLoginRequestMutation__
 *
 * To run a mutation, you first call `useLoginRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginRequestMutation, { data, loading, error }] = useLoginRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginRequestMutation(baseOptions?: Apollo.MutationHookOptions<LoginRequestMutation, LoginRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginRequestMutation, LoginRequestMutationVariables>(LoginRequestDocument, options);
      }
export type LoginRequestMutationHookResult = ReturnType<typeof useLoginRequestMutation>;
export type LoginRequestMutationResult = Apollo.MutationResult<LoginRequestMutation>;
export type LoginRequestMutationOptions = Apollo.BaseMutationOptions<LoginRequestMutation, LoginRequestMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($token: String!) {
  signinAdminWithMagicLinkToken(token: $token) {
    ... on SigninAdminWithMagicLinkTokenError {
      code
    }
    ... on SigninAdminWithMagicLinkTokenSuccess {
      accessToken
      refreshToken
    }
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const RefreshAccessTokenDocument = gql`
    mutation refreshAccessToken($refreshToken: String!) {
  refreshAccessToken(refreshToken: $refreshToken) {
    ... on RefreshAccessTokenResultSuccess {
      refreshToken
      accessToken
    }
    ... on RefreshAccessTokenResultError {
      code
    }
  }
}
    `;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, options);
      }
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;
export const UpdateAppBasicContentDocument = gql`
    mutation UpdateAppBasicContent($type: AppBasicContentType!, $content: AppBasicContentHtmlInput!) {
  updateAppBasicContent(type: $type, content: $content) {
    id
    createdAt
    type
    content {
      title
      html
    }
  }
}
    `;
export type UpdateAppBasicContentMutationFn = Apollo.MutationFunction<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>;

/**
 * __useUpdateAppBasicContentMutation__
 *
 * To run a mutation, you first call `useUpdateAppBasicContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppBasicContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppBasicContentMutation, { data, loading, error }] = useUpdateAppBasicContentMutation({
 *   variables: {
 *      type: // value for 'type'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateAppBasicContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>(UpdateAppBasicContentDocument, options);
      }
export type UpdateAppBasicContentMutationHookResult = ReturnType<typeof useUpdateAppBasicContentMutation>;
export type UpdateAppBasicContentMutationResult = Apollo.MutationResult<UpdateAppBasicContentMutation>;
export type UpdateAppBasicContentMutationOptions = Apollo.BaseMutationOptions<UpdateAppBasicContentMutation, UpdateAppBasicContentMutationVariables>;
export const UpdateAppFaqContentDocument = gql`
    mutation UpdateAppFaqContent($content: AppFaqContentContentInput!) {
  updateAppFaqContent(content: $content) {
    id
    createdAt
    content {
      title
      categories {
        title
        description
        optionalIdentifier
        posts {
          title
          subtitle
          content
          optionalIdentifier
        }
      }
    }
  }
}
    `;
export type UpdateAppFaqContentMutationFn = Apollo.MutationFunction<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>;

/**
 * __useUpdateAppFaqContentMutation__
 *
 * To run a mutation, you first call `useUpdateAppFaqContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppFaqContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppFaqContentMutation, { data, loading, error }] = useUpdateAppFaqContentMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateAppFaqContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>(UpdateAppFaqContentDocument, options);
      }
export type UpdateAppFaqContentMutationHookResult = ReturnType<typeof useUpdateAppFaqContentMutation>;
export type UpdateAppFaqContentMutationResult = Apollo.MutationResult<UpdateAppFaqContentMutation>;
export type UpdateAppFaqContentMutationOptions = Apollo.BaseMutationOptions<UpdateAppFaqContentMutation, UpdateAppFaqContentMutationVariables>;
export const UpdateMaintenanceSettingsDocument = gql`
    mutation updateMaintenanceSettings($go: Boolean!, $premio: Boolean!, $message: String) {
  updateMaintenanceSettings(go: $go, premio: $premio, message: $message) {
    go
    premio
    message
  }
}
    `;
export type UpdateMaintenanceSettingsMutationFn = Apollo.MutationFunction<UpdateMaintenanceSettingsMutation, UpdateMaintenanceSettingsMutationVariables>;

/**
 * __useUpdateMaintenanceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceSettingsMutation, { data, loading, error }] = useUpdateMaintenanceSettingsMutation({
 *   variables: {
 *      go: // value for 'go'
 *      premio: // value for 'premio'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateMaintenanceSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceSettingsMutation, UpdateMaintenanceSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMaintenanceSettingsMutation, UpdateMaintenanceSettingsMutationVariables>(UpdateMaintenanceSettingsDocument, options);
      }
export type UpdateMaintenanceSettingsMutationHookResult = ReturnType<typeof useUpdateMaintenanceSettingsMutation>;
export type UpdateMaintenanceSettingsMutationResult = Apollo.MutationResult<UpdateMaintenanceSettingsMutation>;
export type UpdateMaintenanceSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceSettingsMutation, UpdateMaintenanceSettingsMutationVariables>;
export const UpdatePromotionSettingsReferrerDocument = gql`
    mutation updatePromotionSettingsReferrer($gainAmountInCents: Float!, $id: PromotionSettingsType!, $isActive: Boolean!, $requiredPaymentsCount: Float!, $requiredPaymentsMinimumAmountInCents: Float!, $requiredPaymentsSlidingDays: Float!, $referrerGainAmountInCents: Float, $slidingMonthLimit: Float!, $userLimit: Float!) {
  updatePromotionSettingsReferrer(
    gainAmountInCents: $gainAmountInCents
    id: $id
    isActive: $isActive
    requiredPaymentsCount: $requiredPaymentsCount
    requiredPaymentsMinimumAmountInCents: $requiredPaymentsMinimumAmountInCents
    requiredPaymentsSlidingDays: $requiredPaymentsSlidingDays
    referrerGainAmountInCents: $referrerGainAmountInCents
    slidingMonthLimit: $slidingMonthLimit
    userLimit: $userLimit
  ) {
    gainAmountInCents
    id
    isActive
    referrerGainAmountInCents
    requiredPaymentsCount
    requiredPaymentsMinimumAmountInCents
    requiredPaymentsSlidingDays
    retailerGainAmountInCents
    slidingMonthLimit
    userLimit
    __typename
  }
}
    `;
export type UpdatePromotionSettingsReferrerMutationFn = Apollo.MutationFunction<UpdatePromotionSettingsReferrerMutation, UpdatePromotionSettingsReferrerMutationVariables>;

/**
 * __useUpdatePromotionSettingsReferrerMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionSettingsReferrerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionSettingsReferrerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionSettingsReferrerMutation, { data, loading, error }] = useUpdatePromotionSettingsReferrerMutation({
 *   variables: {
 *      gainAmountInCents: // value for 'gainAmountInCents'
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      requiredPaymentsCount: // value for 'requiredPaymentsCount'
 *      requiredPaymentsMinimumAmountInCents: // value for 'requiredPaymentsMinimumAmountInCents'
 *      requiredPaymentsSlidingDays: // value for 'requiredPaymentsSlidingDays'
 *      referrerGainAmountInCents: // value for 'referrerGainAmountInCents'
 *      slidingMonthLimit: // value for 'slidingMonthLimit'
 *      userLimit: // value for 'userLimit'
 *   },
 * });
 */
export function useUpdatePromotionSettingsReferrerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionSettingsReferrerMutation, UpdatePromotionSettingsReferrerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromotionSettingsReferrerMutation, UpdatePromotionSettingsReferrerMutationVariables>(UpdatePromotionSettingsReferrerDocument, options);
      }
export type UpdatePromotionSettingsReferrerMutationHookResult = ReturnType<typeof useUpdatePromotionSettingsReferrerMutation>;
export type UpdatePromotionSettingsReferrerMutationResult = Apollo.MutationResult<UpdatePromotionSettingsReferrerMutation>;
export type UpdatePromotionSettingsReferrerMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionSettingsReferrerMutation, UpdatePromotionSettingsReferrerMutationVariables>;
export const UpdatePromotionSettingsRetailerDocument = gql`
    mutation updatePromotionSettingsRetailer($gainAmountInCents: Float!, $id: PromotionSettingsType!, $isActive: Boolean!, $requiredPaymentsCount: Float!, $requiredPaymentsMinimumAmountInCents: Float!, $requiredPaymentsSlidingDays: Float!, $retailerGainAmountInCents: Float, $slidingMonthLimit: Float!, $userLimit: Float!) {
  updatePromotionSettingsRetailer(
    gainAmountInCents: $gainAmountInCents
    id: $id
    isActive: $isActive
    requiredPaymentsCount: $requiredPaymentsCount
    requiredPaymentsMinimumAmountInCents: $requiredPaymentsMinimumAmountInCents
    requiredPaymentsSlidingDays: $requiredPaymentsSlidingDays
    retailerGainAmountInCents: $retailerGainAmountInCents
    slidingMonthLimit: $slidingMonthLimit
    userLimit: $userLimit
  ) {
    gainAmountInCents
    id
    isActive
    retailerGainAmountInCents
    requiredPaymentsCount
    requiredPaymentsMinimumAmountInCents
    requiredPaymentsSlidingDays
    retailerGainAmountInCents
    slidingMonthLimit
    userLimit
    __typename
  }
}
    `;
export type UpdatePromotionSettingsRetailerMutationFn = Apollo.MutationFunction<UpdatePromotionSettingsRetailerMutation, UpdatePromotionSettingsRetailerMutationVariables>;

/**
 * __useUpdatePromotionSettingsRetailerMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionSettingsRetailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionSettingsRetailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionSettingsRetailerMutation, { data, loading, error }] = useUpdatePromotionSettingsRetailerMutation({
 *   variables: {
 *      gainAmountInCents: // value for 'gainAmountInCents'
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      requiredPaymentsCount: // value for 'requiredPaymentsCount'
 *      requiredPaymentsMinimumAmountInCents: // value for 'requiredPaymentsMinimumAmountInCents'
 *      requiredPaymentsSlidingDays: // value for 'requiredPaymentsSlidingDays'
 *      retailerGainAmountInCents: // value for 'retailerGainAmountInCents'
 *      slidingMonthLimit: // value for 'slidingMonthLimit'
 *      userLimit: // value for 'userLimit'
 *   },
 * });
 */
export function useUpdatePromotionSettingsRetailerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionSettingsRetailerMutation, UpdatePromotionSettingsRetailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromotionSettingsRetailerMutation, UpdatePromotionSettingsRetailerMutationVariables>(UpdatePromotionSettingsRetailerDocument, options);
      }
export type UpdatePromotionSettingsRetailerMutationHookResult = ReturnType<typeof useUpdatePromotionSettingsRetailerMutation>;
export type UpdatePromotionSettingsRetailerMutationResult = Apollo.MutationResult<UpdatePromotionSettingsRetailerMutation>;
export type UpdatePromotionSettingsRetailerMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionSettingsRetailerMutation, UpdatePromotionSettingsRetailerMutationVariables>;